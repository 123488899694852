<template>
  <section class="user-page">
    <div class="flex row">
      <div class="sidebar">
        <avatar :user="$auth.user" />
        <div class="username">Total Score: {{totalScore}}</div>
        <nuxt-link v-if="$auth.user" v-on:click.native="logoutClickHandler" exact to="#/">Logout</nuxt-link>
      </div>
      <card title="Recent Challenges Completed" classes="wide">
        Todo
      </card>
    </div>
  </section>
</template>

<script>
import Card from '~/components/Card';
import userByUsernameQuery from '~/apollo/queries/UserByUsername.gql';
import './index.scss';

export default {
  components: {
    Card
  },
  data() {
    return {
      user: undefined,
      error404: false
    }
  },
  asyncData({ app, params }) {
    const { username } = params;

    return app.apolloProvider.defaultClient.query({
      query: userByUsernameQuery, 
      variables: { username }
    }).then(userResult => {
      if (!userResult.data.users) {
        return {
          error404: true
        }
      }

      return {
        user: userResult.data.users[0]
      }
    });
  },
  methods: {
    logoutClickHandler() {
      this.$auth.logout();
    }
  },
  computed: {
    username() {
      return this.user ? this.user.username: '404';
    },
    totalScore() {
      return this.user ? this.user.status.score.total : 'N/A';
    },
    userIsMe() {
      return this.user && this.$auth.loggedIn && this.user.username === this.$auth.user.username;
    }
  }
}
</script>
