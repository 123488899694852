<template>
  <div :class="`card ${classes || ''}`">
    <h1 class="card-title"><slot name="title">{{title}}</slot></h1>
    <div class="card-body">
      <slot/>
    </div>
  </div>
</template>

<script>
  import './Card.scss';

  export default {
    props: ['title', 'classes']
  };
</script>
